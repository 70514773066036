<template>
  <v-menu v-model="isOpened" right :close-on-content-click="false">
    <template #activator="{ on }">
      <c-btn
        icon
        :width="isMobile ? 36 : 26"
        :height="isMobile ? 36 : 26"
        class="secondary-lighten rounded"
        :icon-props="{
          icon: '$config',
          size: isMobile ? '19' : '14',
          color: 'black'
        }"
        v-on="on"
        @click="gtmPush(({ event: GTM_EVENTS.CAMPAIGNS_ACTION_SETTINGS_COLUMNS }))"
      />
    </template>

    <v-card class="mx-auto" max-width="400">
      <v-toolbar flat>
        <c-btn
          icon
          :icon-props="{
            icon: '$close',
            size: '20'
          }"
          @click="isOpened = false"
        />
        <v-toolbar-title>{{ $t('main.shown_columns') }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-chip-group
          :value="shownHeadersIndexes"
          column
          multiple
          @change="changeHeadersIndexes($event)"
        >
          <v-chip
            v-for="(header, i) in headers"
            :key="i"
            :disabled="header.hideable === false"
            filter
            outlined
          >
            {{ $t(header.text) }}
          </v-chip>
        </v-chip-group>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { GTM_EVENTS } from '@clickadilla/components/constants/gtm-events.js'
  import gtmPush from '@/services/utils/gtm-push.js'

  export default {
    name: 'ShownHeaders',
    components: {
      CBtn
    },
    props: {
      headers: { type: Array, default: () => [] },
      localKey: { type: String, required: true }
    },
    data() {
      return {
        isOpened: false,
        defaultHeadersIndexes: [],
        shownHeadersIndexes: [],
        GTM_EVENTS
      }
    },
    computed: {
      breakpoint() {
        return this.$vuetify.breakpoint
      },
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      }
    },
    created() {
      this.defaultHeadersIndexes = this.headers.map((header, index) => index)
      const localSettings = this.getLocalSettings()
      this.shownHeadersIndexes = localSettings || this.defaultHeadersIndexes
      const shownHeaders = this.headers.filter((_, index) => this.shownHeadersIndexes.includes(index))
      this.emitShownHeaders(shownHeaders)
    },
    methods: {
      gtmPush,
      getLocalSettings() {
        const isSameHeaders = JSON.stringify(this.defaultHeadersIndexes)
          === localStorage.getItem(`table.${this.localKey}.version`)
        return isSameHeaders ? JSON.parse(localStorage.getItem(`table.${this.localKey}`)) : false
      },
      setLocalSettings(data) {
        localStorage.setItem(`table.${this.localKey}.version`, JSON.stringify(this.defaultHeadersIndexes))
        localStorage.setItem(`table.${this.localKey}`, JSON.stringify(data))
      },
      emitShownHeaders(data) {
        this.$emit('headers-updated', data)
      },
      changeHeadersIndexes(headersIndexes) {
        this.shownHeadersIndexes = headersIndexes
        const shownHeaders = this.headers.filter((_, index) => headersIndexes.includes(index))
        this.emitShownHeaders(shownHeaders)
        this.setLocalSettings(headersIndexes)
      }
    }
  }
</script>
