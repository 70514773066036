var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"right":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('c-btn',_vm._g({staticClass:"secondary-lighten rounded",attrs:{"icon":"","width":_vm.isMobile ? 36 : 26,"height":_vm.isMobile ? 36 : 26,"icon-props":{
        icon: '$config',
        size: _vm.isMobile ? '19' : '14',
        color: 'black'
      }},on:{"click":function($event){_vm.gtmPush(({ event: _vm.GTM_EVENTS.CAMPAIGNS_ACTION_SETTINGS_COLUMNS }))}}},on))]}}]),model:{value:(_vm.isOpened),callback:function ($$v) {_vm.isOpened=$$v},expression:"isOpened"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"400"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('c-btn',{attrs:{"icon":"","icon-props":{
          icon: '$close',
          size: '20'
        }},on:{"click":function($event){_vm.isOpened = false}}}),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('main.shown_columns')))])],1),_c('v-card-text',[_c('v-chip-group',{attrs:{"value":_vm.shownHeadersIndexes,"column":"","multiple":""},on:{"change":function($event){return _vm.changeHeadersIndexes($event)}}},_vm._l((_vm.headers),function(header,i){return _c('v-chip',{key:i,attrs:{"disabled":header.hideable === false,"filter":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")])}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }