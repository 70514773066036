<template>
  <div>
    <c-select
      v-model="sortByMutable"
      hide-details
      :loading="isLoading"
      :items="sortItems"
      :outlined="false"
      :height="30"
      background-color="secondary-lighten"
      border-color="secondary-lighten"
      :placeholder="$t('main.sort_by')"
      item-text="label"
      clearable
      item-value="id"
    />
  </div>
</template>

<script>
  import CSelect from '@clickadilla/components/ui/CSelect.vue'

  export default {
    name: 'SortingField',
    components: {
      CSelect
    },
    props: {
      isLoading: {
        type: Boolean,
        required: true
      },
      options: {
        type: Object,
        default: () => ({})
      }
    },
    computed: {
      sortItems() {
        return [
          {
            id: 1,
            sortBy: 'id',
            sortDesc: true,
            label: `ID ${this.$t('main.desc')}`
          },
          {
            id: 2,
            sortBy: 'id',
            sortDesc: false,
            label: `ID ${this.$t('main.asc')}`
          },
          {
            id: 3,
            sortBy: 'name',
            sortDesc: true,
            label: this.$t('main.name') + this.$t('main.desc')
          },
          {
            id: 4,
            sortBy: 'name',
            sortDesc: false,
            label: this.$t('main.name') + this.$t('main.asc')
          },
          {
            id: 5,
            sortBy: 'adFormatType',
            sortDesc: true,
            label: this.$t('main.ad_format') + this.$t('main.desc')
          },
          {
            id: 6,
            sortBy: 'adFormatType',
            sortDesc: false,
            label: this.$t('main.ad_format') + this.$t('main.asc')
          },
          {
            id: 7,
            sortBy: 'status',
            sortDesc: true,
            label: this.$t('main.status') + this.$t('main.desc')
          },
          {
            id: 8,
            sortBy: 'status',
            sortDesc: false,
            label: this.$t('main.status') + this.$t('main.asc')
          },
          {
            id: 9,
            sortBy: 'approvalStatus',
            sortDesc: true,
            label: this.$t('main.approval_status') + this.$t('main.desc')
          },
          {
            id: 10,
            sortBy: 'approvalStatus',
            sortDesc: false,
            label: this.$t('main.approval_status') + this.$t('main.asc')
          }
        ]
      },
      sortByMutable: {
        get() {
          return this.sortItems.find(
            (item) => item.sortDesc === this.options.sortDesc[0] && item.sortBy === this.options.sortBy[0]
          )
        },
        set(selectId) {
          const foundSort = this.sortItems.find((item) => item.id === selectId)
          if (foundSort) {
            this.$emit('change-sort-by', [foundSort.sortBy])
            this.$emit('change-sort-desc', [foundSort.sortDesc])
          } else {
            this.$emit('change-sort-by', [])
            this.$emit('change-sort-desc', [])
          }
        }
      }
    }
  }
</script>
